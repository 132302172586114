export namespace DOM {
  export function byId<T extends HTMLElement>(id: string): T {
    return document.getElementById(id) as T;
  }

  export function onIdClick(id: string, callback: (ev?: MouseEvent, elem?: HTMLElement) => void): void {
    const elem = byId(id);
    elem.addEventListener('click', ev => { callback(ev, elem); });
  }

  export function toElem(html: string): HTMLElement {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.children[0] as HTMLElement;
  }

  export function toggleClass(ids: string[], shouldSet: boolean, className: string): void {
    for (const id of ids) {
      const elem = byId(id);
      if (shouldSet) {
        elem.classList.add(className);
      } else {
        elem.classList.remove(className);
      }
    }
  }
}