import {Ajax} from './ajax';
import {CommonUser, Declaration, FieldError, Payments, Payroll} from './common_repository';

export namespace Repo {
  export function checkAuth(onUser: Function, onGuest: Function): void {
    if (window.sessionStorage.getItem('SessionId') || window.sessionStorage.getItem('Session-Id')) {
      Ajax.get('users', prepareAuthHeaders()).onSuccess(() => { onUser(); }).onFailure(() => { clearAuthHeaders(); onGuest(); });
    } else {
      onGuest();
    }
  }

  export type LoginCredentials = { username: string; password: string; }
  export function login(credentials: LoginCredentials): Ajax.Callbacks<void, FieldError[]> {
    return Ajax.put('users', credentials).onSuccess((auth: { SessionId: string; pass: string; }): void => {
      window.sessionStorage.setItem('SessionId', auth.SessionId);
      window.sessionStorage.setItem('PassId', auth.pass);
    }) as Ajax.Callbacks<void, FieldError[]>;
  }

  export function getPassId(): string {
    return window.sessionStorage.getItem('PassId');
  }

  export type Registration = { username: string; email: string; password: string; }
  export function register(reg: Registration): Ajax.Callbacks<void, FieldError[]> {
    return Ajax.post('users', reg);
  }

  export function logout(): Ajax.Callbacks<void, void> {
    return Ajax.del('users', undefined, prepareAuthHeaders()).onComplete(clearAuthHeaders) as Ajax.Callbacks<void, void>;
  }

  export type PasswordResetEmail = { email: string; }
  export function triggerPasswordReset(email: PasswordResetEmail): Ajax.Callbacks<void, FieldError[]> {
    return Ajax.post('users/passwords', email);
  }

  export type PasswordReset = { code: string; password: string; };
  export function resetPassword(reset: PasswordReset): Ajax.Callbacks<void, FieldError[]> {
    return Ajax.put('users/passwords', reset);
  }


  export function getProfileInfo(): Ajax.Callbacks<Profile, void> {
    return Ajax.get('users/profiles', prepareAuthHeaders());
  }

  export function triggerAccountClose(): Ajax.Callbacks<void, void> {
    return Ajax.del('users/accounts', undefined, prepareAuthHeaders());
  }

  export type NotificationSettings = { hasNotifications: boolean; }
  export function toggleNotifications(settings: NotificationSettings): void {
    Ajax.post('users/profiles', settings, prepareAuthHeaders());
  }

  export type Profile = {
    hasNotifications: boolean;
    user: ProfileUser;
  }
  export type ProfileUser = CommonUser & {
    personalNumber: string;
    companyName: string;
    bulstat: string;
  }

  export type Article = { id: number; title: string; content: string; createdOn: string; editedOn: string; }
  export function getArticles(limit: number = 1000, excludeId: number = -1): Ajax.Callbacks<Article[], void> {
    return Ajax.get(`users/articles?limit=${limit}&excludeId=${excludeId}`);
  }

  export function getDeclarationMonths(): Ajax.Callbacks<string[], void> {
    return Ajax.get('users/compensations', prepareAuthHeaders());
  }

  export function getDeclarationForMonth(monthYear: Date | string): Ajax.Callbacks<Declaration, FieldError[]> {
    return Ajax.get('users/compensations?monthYear=' + formatServerMonthYear(monthYear), prepareAuthHeaders());
  }

  export function addDeclaration(declaration: Declaration): Ajax.Callbacks<void, FieldError[]> {
    return Ajax.post('users/compensations', declaration, prepareAuthHeaders());
  }

  export function getPayroll(monthYear: string): Ajax.Callbacks<Payroll, FieldError[]> {
    return Ajax.get('users/inqueries/payrolls?monthYear=' + monthYear, prepareAuthHeaders());
  }

  export function getPayments(): Ajax.Callbacks<Payments, void> {
    return Ajax.get('users/payments', prepareAuthHeaders());
  }

  export function addPayment(monthsCount: number): Ajax.Callbacks<void, void> {
    return Ajax.post('users/payments', {monthsCount: monthsCount}, prepareAuthHeaders());
  }

  function clearAuthHeaders(): void {
    window.sessionStorage.clear();
  }

  function prepareAuthHeaders(): Ajax.Header[] {
    return [
      { name: 'X-Session-Id', value: window.sessionStorage.getItem('Session-Id') },
      { name: 'X-SessionId', value: window.sessionStorage.getItem('SessionId') }
    ];
  }

  function formatServerMonthYear(date: Date | string): string {
    return typeof date === 'string' ? date : (date.getMonth() + 1) + "/" + date.getFullYear();
  }
}