import { ArticlePreview } from '../core/article-preview';
import { DOM } from '../core/dom';
import { Repo } from '../core/repository';

declare var Splide;

export namespace LatestArticles {
  export function onload(): void {
    const excludeId = resolveExcludeId();
    Repo.getArticles(5, excludeId).onSuccess(render);
  }

  function resolveExcludeId(): number {
    const idHolder = DOM.byId('article-id');
    if (!idHolder) {
      return -1;
    }
    const dataId = idHolder.getAttribute('data-id');
    return dataId ? parseInt(dataId) : -1;
  }

  function render(articles: Repo.Article[]): void {
    DOM.byId('latest-articles').innerHTML = `
      <h3>Последни статии</h3>
      <div id="splide-container" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            ${renderArticles(articles)}
          </ul>
        </div>
      </div>`;
    new Splide('#splide-container', {
      classes: {
        arrows: 'splide__arrows articles-arrows',
        arrow : 'splide__arrow articles-arrow',
        page  : 'splide__pagination__page articles-page'
      }
    }).mount();
  }

  function renderArticles(articles: Repo.Article[]): string {
    return articles.map(article => {
      return `
        <li class="splide__slide">
        ${ArticlePreview.create(article)}
        </li>`;
    }).join('');
  }
}