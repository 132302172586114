import {Repo} from './repository';

export namespace ArticlePreview {
  export function create(article: Repo.Article, heading: 'h2' | 'h4' = 'h4'): string {
    return `
      <div class="article-preview-container">
        <div class="article-preview">
          <${heading}>${article.title}</${heading}>
          ${article.content}
          <span class="blur-bot"></span>
        </div>
        <a href="/public/articles/${article.title}" class="read-more">Виж повече <i class="fa-solid fa-chevron-right"></i></a>
      </div>`;
  }
}